<template>
   <div class="page-internal page-contato">
      <div class="topo-page">
         <b-container class="h-100">  
            <h1 class="title-big">FALE COM <span>A INSIGHT</span></h1>
            <p>Não fique com dúvidas! Entre em contato conosco ou solicite um orçamento aqui no formulário</p>

            <div class="img-tit-page">
               <u-animate-container>
                  <u-animate
                     name="animate__fadeInUp"
                     delay="0.5s"
                     duration="2s"
                     :iteration="1"
                     :offset="0"
                     animateClass="animated"
                     :begin="false"
                     >
                     <i><img src="@/assets/images/img-topo-contato.png" alt=""></i>
                  </u-animate>
               </u-animate-container>
            </div>
         </b-container>
      </div>

      <section class="section-contato">
         <b-container class="h-100">  
            <h2 class="title-section">CONTATO <span>VAMOS TRABALHAR JUNTOS?</span></h2>
            <div class="enderece">
               <p class="">
                  <a href="mailto:comercial@insightsolution.com.br">comercial@insightsolution.com.br</a><br>
                  Curitiba - Paraná
               </p>
            </div>

            <!-- <div class="bt-whats">
               <a href="https://api.whatsapp.com/send?phone=5541996748555" target="_blank" rel="noopener"><i><img src="@/assets/images/icon-whats.svg" alt=""></i>Envie uma mensagem agora</a>
            </div> -->
            
         </b-container>
      </section>

      <BoxFrase></BoxFrase>
   </div>
</template>

<script>
   import BoxFrase from '@/components/BoxFrase/BoxFrase';
   import { UAnimateContainer, UAnimate } from "vue-wow";

   export default {
      name: 'Contato',
      components: {
         BoxFrase,
         UAnimateContainer,
         UAnimate,
      }
   }
</script>


<style lang="scss">
  @import "Contato.scss";
</style>
